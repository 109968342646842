import Head from 'next/head';
import { FC, isValidElement, cloneElement } from 'react';
import { useIntl } from 'react-intl';
import { usePreviousPathname } from '~/hooks/usePreviousPathname';

type AppSkeltonProps = {
  children: React.ReactNode
  title?: string
}

const AppSkelton: FC<AppSkeltonProps> = ({ children, title }) => {
  const { formatMessage } = useIntl();
  // const { formatMessage } = {
  //   formatMessage(any: any) {
  //     return any.id
  //   }
  // }
  const element = children;
  const content = isValidElement(element) ? cloneElement(element) : null;

  usePreviousPathname();

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="viewport-fit=cover, initial-scale=1.0, width=device-width" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <title>{title || formatMessage({ id: 'app.sitename' })}</title>
        <meta name="description" content={formatMessage({ id: 'app.description' })} />
        <meta property="og:title" content={title || formatMessage({ id: 'app.sitename' })} />
        <meta property="og:description" content={formatMessage({ id: 'app.description' })} />
        <meta property="og:image" content="/ogp.png" />
      </Head>
      {content}
    </>
  );
};

AppSkelton.displayName = 'AppSkelton';

export default AppSkelton;
