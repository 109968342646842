import { useEffect, useCallback } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  confirmPasswordReset,
  verifyPasswordResetCode,
  UserCredential,
} from 'firebase/auth';
import * as Sentry from '@sentry/nextjs';
import { useRecoilState } from 'recoil';
import { authUserState } from '~/store/auth';
import { AuthUser } from '~/domain/entity/auth';
import { auth, googleProvider } from '~/helpers/firebase';

interface AuthContext {
  currentUser: AuthUser;
  setCurrentUser: (props: AuthUser) => void
  signup: () => void
  signout: () => Promise<void>
  signupWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential>
  loginWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential>
  resetNewPassword: (oobCode: string, password: string) => Promise<string>
  getIdToken: () => Promise<string | undefined>
}

let isStarted = false;

export function useAuthContext(): AuthContext {
  const [currentUser, setCurrentUser] = useRecoilState(authUserState);

  const signup = () => {
    console.debug('[AUTH] signup');
    signInWithPopup(auth, googleProvider)
      .catch((error) => {
        if (error.code === 'auth/cancelled-popup-request') {
          console.debug('[AUTH] popup closed');
        } else if (error.code === 'auth/popup-blocked') {
          console.debug('[AUTH] popup blocked');
        }

        Sentry.captureException(error);
      });
  };

  const signout = useCallback(() => {
    console.debug('[AUTH] signOut');
    window.localStorage.removeItem('token');
    return auth.signOut();
  }, []);

  const signupWithEmailAndPassword = useCallback((email: string, password: string) => {
    console.debug('[AUTH] signupWithEmailAndPassword');
    return createUserWithEmailAndPassword(auth, email, password);
  }, []);

  const loginWithEmailAndPassword = useCallback((email: string, password: string) => {
    console.debug('[AUTH] loginWithEmailAndPassword');
    return signInWithEmailAndPassword(auth, email, password);
  }, []);

  // https://firebase.google.com/docs/auth/custom-email-handler?hl=ja#create_the_email_action_handler_page
  const resetNewPassword = useCallback(
    (
      oobCode: string,
      newPassword: string,
    ): Promise<string> => new Promise((resolve, reject) => {
      console.debug('[AUTH] verify code to reset password');
      // Verify the password reset code is valid.
      verifyPasswordResetCode(auth, oobCode)
        .then((accountEmail) => {
          console.debug('[AUTH] verified code and save new password');
          // Save the new password.
          confirmPasswordReset(auth, oobCode, newPassword)
            .then(() => {
              console.debug('[AUTH] saved new password');
              resolve(accountEmail);
            })
            .catch(reject);
        })
        .catch(reject);
    }),
    [],
  );

  // debug
  const getIdToken = useCallback(async (): Promise<string | undefined> => {
    const token = await auth.currentUser?.getIdToken(true);

    return token;
  }, []);

  useEffect(() => {
    if (isStarted) return;

    isStarted = true;

    auth.onAuthStateChanged(async (user) => {
      setCurrentUser({
        requested: true,
        uid: user?.uid ?? '',
        email: user?.email ?? '',
      });

      const newToken = await user?.getIdToken(true);
      const tokenResult = await user?.getIdTokenResult(true)
        .catch(() => null);

      console.debug('authExchange: get token', newToken);

      if (newToken) {
        window.localStorage.setItem('token', newToken);
      } else {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('expiration');
      }

      if (tokenResult) {
        const expiration = new Date(tokenResult.expirationTime);
        const needRefresh = (expiration.getTime() - Date.now()) <= 0;

        window.localStorage.setItem('expiration', `${expiration.getTime()}`);

        if (needRefresh) {
          console.debug('[AUTH] need to refresh');
          auth.currentUser?.getIdTokenResult(true)
            .catch(() => null);
        }
      }

      console.debug('[AUTH] state changed', user?.uid);
    });
  }, [setCurrentUser]);

  return {
    currentUser,
    setCurrentUser: (props: AuthUser) => {
      setCurrentUser(props);
    },
    signupWithEmailAndPassword,
    loginWithEmailAndPassword,
    resetNewPassword,
    signup,
    signout,
    getIdToken,
  };
}
