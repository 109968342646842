import { atom } from 'recoil';
import { AuthUser } from '~/domain/entity/auth';

export const authUserState = atom<AuthUser>({
  key: 'auth',
  default: {
    requested: false,
    uid: '',
    email: '',
  },
});
