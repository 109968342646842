import { Box } from '@chakra-ui/react';
import { EmochanSpinner } from '@emochan-cabinet/ui';

export default function FullPageSpinner(): JSX.Element {
  return (
    <Box h="100vh">
      <EmochanSpinner />
    </Box>
  );
}

FullPageSpinner.displayName = 'FullPageSpinner';
