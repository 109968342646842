import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useRecoilState } from 'recoil';
import { historyPathnameState } from '~/store/history';

export function usePreviousPathname(): { previousPathname: string, removeHistory: () => void } {
  const { pathname } = useRouter();
  const [history, setHistory] = useRecoilState(historyPathnameState);
  const lastHistoryIndex = history.length - 2;
  const previousPathname = history[lastHistoryIndex > 0 ? lastHistoryIndex : 0];

  function removeHistory() {
    setHistory((prev) => {
      if (prev.length > 1) {
        return prev.filter((_, index) => index !== prev.length - 1);
      }

      return prev;
    });
  }

  useEffect(() => {
    setHistory((prev) => {
      if (prev[prev.length - 1] !== pathname) return [...prev, pathname];
      return prev;
    });
  }, [pathname, setHistory]);

  return { previousPathname, removeHistory };
}
